<template>
  <div class="dashboard-page">
    <div class="data-panel">
      <div class="left">
        <div class="data-list">
          <div class="data-item">
            <div class="title">校区数量</div>
            <div class="num">{{ pageData.campus }}</div>
          </div>
          <div class="data-item">
            <div class="title">课程数量</div>
            <div class="num">{{ pageData.course }}</div>
          </div>
          <div class="data-item">
            <div class="title">存储数量</div>
            <div class="num">{{ pageData.bucket }}</div>
          </div>
          <div class="data-item">
            <div class="title">管理员数量</div>
            <div class="num">{{ pageData.account }}</div>
          </div>
          <div class="data-item">
            <div class="title">老师数量</div>
            <div class="num">{{ pageData.teacher }}</div>
          </div>
        </div>
        <div class="ad-area">
          <div class="left-ad">
            <swiper :options="swiperOptions" ref="mySwiper">
              <!-- slides -->
              <swiper-slide v-for="(item, index) in pageData.left_ad" :key="index">
                <img :src="item.img" @click="toLink(item.link)"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
          <div class="right-ad">
            <swiper :options="swiperOptions" ref="mySwiper">
              <!-- slides -->
              <swiper-slide v-for="(item, index) in pageData.right_ad" :key="index">
                <img :src="item.img" @click="toLink(item.link)"/>
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
          </div>
        </div>
      </div>
      <div class="right card-info">
        <div class="words">我们随时为您提供服务</div>
        <div class="line"></div>
        <div class="phone">
          {{pageData.phone}}
        </div>
        <div class="words">扫码联系小助手</div>
        <div class="words">帮助您解决产品问题</div>
        <div class="ewm">
          <el-image :src="pageData.ewm"></el-image>
        </div>
        <div class="link-title">查询剩余流量</div>
        <div class="to-check">
          <el-button type="primary" @click="toLink('https://cdn.1lsq.cn/')" round>点击查询</el-button>
        </div>
      </div>
    </div>
    <div class="list-panel">
      <div class="left">
        <div class="title">课程播放总时长</div>
        <el-table :data="pageData.courseDataList" border style="width: 100%" max-height="380">
          <el-table-column type="index" label="#" width="40"></el-table-column>
          <el-table-column prop="title" label="课程"></el-table-column>
          <el-table-column prop="campus_title" label="所属学校"></el-table-column>
          <el-table-column prop="view_time" label="播放时长总计" fixed="right">
            <template slot-scope="scope">
              <el-tag type="success" effect="plain">{{ toTime(scope.row.view_time) }}</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <div class="read-more" @click="toCourse">
          查看更多
        </div>
      </div>
      <div class="right">
        <div class="title">教师登录时长</div>
        <el-table :data="pageData.teacherDataList" border style="width: 100%" max-height="380">
          <el-table-column type="index" label="#" width="60"></el-table-column>
          <el-table-column prop="username" label="教师帐号"></el-table-column>
          <el-table-column prop="campus_title" label="所属学校"></el-table-column>
          <el-table-column prop="last_time" label="最近登录时间">
            <template slot-scope="scope">
              <el-tag type="success" effect="plain" v-if="scope.row.last_time">{{ $moment(scope.row.last_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-tag>
              <el-tag type="info" effect="plain" v-else>从未登录</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="last_ip" label="最近登录IP"></el-table-column>
          <el-table-column prop="total_time" fixed="right" label="上课总时长">
            <template slot-scope="scope">
              <el-tag type="success" effect="plain">{{ toTime(scope.row.total_time) }}</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <div class="read-more" @click="toTeacher">
          查看更多
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  data () {
    return {
      pageData: {
        account: 0,
        bucket: 0,
        campus: 0,
        course: 0,
        teacher: 0,
        left_ad: [],
        right_ad: [],
        // 课程
        courseDataList: [],
        // 老师
        teacherDataList: []
      },
      swiperOptions: {
        direction: 'horizontal',
        loop: false,
        autoplay: true,
        delay: 2000,
        pagination: {
          el: '.swiper-pagination'
        }
      }
    }
  },
  created () {
    this.getPageData()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/dashboard')
      if (res.code === 200) {
        this.pageData.account = res.data.account
        this.pageData.bucket = res.data.bucket
        this.pageData.campus = res.data.campus
        this.pageData.course = res.data.course
        this.pageData.teacher = res.data.teacher
        this.pageData.left_ad = res.data.left_ad
        this.pageData.right_ad = res.data.right_ad
        this.pageData.phone = res.data.phone
        this.pageData.ewm = res.data.ewm
        this.pageData.courseDataList = res.data.view_data
        this.pageData.teacherDataList = res.data.teacher_data
      } else {
        this.$message.error(res.msg)
      }
    },
    toCourse () {
      this.$router.push('/admin/teacher')
    },
    toTeacher () {
      this.$router.push('/admin/content/courses/course')
    },
    toLink (url) {
      console.log(url)
      window.open(url, '_blank')
    },
    // 将时间转换为天
    toTime (machtime) {
      if (machtime > 0) {
        const s = Math.floor(machtime / 1) % 60
        machtime = Math.floor(machtime / 60)
        const i = machtime % 60
        machtime = Math.floor(machtime / 60)
        const h = machtime % 24
        machtime = Math.floor(machtime / 24)
        const d = machtime
        return d + ' 天 ' + h + ' 时 ' + i + ' 分 ' + s + ' 秒 '
      } else {
        return '暂无播放'
      }
    }
  }
}
</script>

<style scoped>
.dashboard-page{}
.data-panel{
  display: flex;
  position: relative;
}
.data-panel .left{
  display: inline-block;
  width: calc(100% - 320px);
}
.data-panel .data-list{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
.data-panel .data-list .data-item{
  background-color: #FFFFFF;
  width: 18%;
  padding: 20px 20px;
}
.data-panel .data-list .data-item .num{
  font-size: 28px;
  font-weight: bold;
  color: #6777ef;
  margin-top: 6px;
  text-align: center;
}
.data-panel .data-list .data-item .title{
  font-size: 14px;
  text-align: left;
  font-weight: bold;
  color: #606266;
}
.data-panel .left .ad-area{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}
.data-panel .left .ad-area .left-ad{
  width: 48%;
}
.data-panel .left .ad-area .right-ad{
  width: 48%;
}
.data-panel .left .swiper-slide{}
.data-panel .left .swiper-slide img{
  width: 100%;
  height: auto;
  cursor: pointer;
}
.data-panel .right{
  width: 300px;
  margin-left: 20px;
  background-color: #FFFFFF;
  padding: 20px 10px;
  text-align: center;
  display: inline-block;
}
.data-panel .right .words{
  font-size: 14px;
  text-align: center;
  color: #606266;
}
.data-panel .right .line {
  width: 40px;
  height: 2px;
  background-color: #333333;
  border-radius: 2px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
}
.data-panel .right .phone{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin: 22px auto 12px auto;
  border-bottom: 1px solid #C0C4CC;
  width: fit-content;
  padding: 0 20px 20px 20px;
}
.data-panel .right .ewm{
  width: 180px;
  height: 180px;
  margin: 14px auto 20px auto;
  border: 1px solid #C0C4CC;
  padding: 10px;
}
.data-panel .right .ewm .el-image{
  width: 100%;
  height: 100%;
}
.list-panel{
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 40px;
}
.list-panel .left {
  width: 38%;
}
.list-panel .right{
  width: 58%;
}
.list-panel .title{
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.list-panel .el-table{
}
.list-panel .read-more{
  font-size: 14px;
  text-align: right;
  margin-top: 10px;
  color: #909399;
  cursor: pointer;
}
.to-check{
  margin-top: 20px;
  text-align: center;
}
.link-title{
  font-size: 16px;
  text-align: center;
  border-top: 1px dashed #C0C4CC;
  padding-top: 10px;
  font-weight: bold;
}
</style>
